import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
const Faq = () => ( <Layout>
  <div className="skipScroll">
  <Helmet
  title={'temtum FAQ | Temporal Blockchain Frequently Asked Questions '}
  meta={[
      {
        name: 'description',
        content: 'temtum is complex technology built on the Temporal Blockchain, with many questions asked on the benefits of TEM currency in our FAQs.'
      }
    ]}
    />
    <div className="hd-space"/>
    <div className="page skipScroll">
      <section className="text-white skipScroll bg2 py-5">
        <div className="blueblue video-overlay"/>
        <div className="wow3 video-overlay"/>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="display-2 font-weight-normal pb-3X">
                Frequent Questions
              </h1>
              <h2 className="display-4  pb-4X">
                Answers to questions you need to know
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section id="" className=" skipScroll">
        <div className="container faq">
          <div className="row">
            <div className="col-12 col-md-3">
              <ul className="list-group help-group tabs-left">
                <div className="navX flex-columnX nav-pillsX faq-list list-group nav nav-tabs" id="v-pills-tab" role="tablist">
                  <a className="nav-link faq-list-group-item active" id="v-pills-tab1-tab" data-toggle="pill" href="#faqtab1" role="tab" aria-controls="v-pills-tab1" aria-expanded="true">
                  Business / Currency
                  </a>
                  <a className="nav-link faq-list-group-item" id="v-pills-tab2-tab" data-toggle="pill" href="#faqtab2" role="tab" aria-controls="v-pills-tab2" aria-expanded="true">
                    Technology
                  </a>
                  <a className="nav-link faq-list-group-item list-group-item-actionX" id="v-pills-tab3-tab" data-toggle="pill" href="#faqtab3" role="tab" aria-controls="v-pills-tab3" aria-expanded="true">
                    Network
                  </a>
                </div>
              </ul>
            </div>
            <div className="col-12 col-md-9">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="faqtab1" role="tabpanel" aria-labelledby="v-pills-home-tab">
                  <div className="panel-group" id="help-accordion-1">
                    <h3 className="display-4 pb-4 border-bottom">
                      Business / Currency FAQ
                    </h3>
                    <div className="panel panel-default panel-help">
                      <a href="#t1" data-toggle="collapse" data-parent="#help-accordion-1" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          If the Temporal Blockchain technology owned by Dragon Infosec, is temtum a centralised currency?

                          </h2>
                        </div>
                      </a>
                      <div id="t1" className="collapse in">
                        <div className="panel-body">
                          <p>temtum is not a centralized currency for the following reasons:</p>
                        <p>  It has a full and unencumbered license to the Temporal Blockchain technology and beginning in 2019 is providing its own development and enhanced technology. Furthermore it is decentralized because it has 100 servers distributed across the globe, and does not allow for a single entity to manipulate / manage users funds.
</p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default panel-help">
                      <a href="#t2" data-toggle="collapse" data-parent="#help-accordion-1" className="collapsed">
                        <div className="panel-heading">
                          <h2>

                          Who are Dragon Infosec?

                          </h2>
                        </div>
                      </a>
                      <div id="t2" className="collapse">
                        <div className="panel-body">
                          <p>
                          Dragon infosec was the incubator company that began the blockchain development back in 2016 that was started by Richard Dennis.

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default panel-help">

                      <div className="panel panel-default panel-help">
                        <a href="#t4" data-toggle="collapse" data-parent="#help-accordion-1" className="collapsed">
                          <div className="panel-heading">
                            <h2>
Can the blockchain be replicated?
                            </h2>
                          </div>
                        </a>
                      <div id="t4" className="collapse">
                        <div className="panel-body">
                          <p>
                            Dragon will not and have not made the Temporal Blockchain open source.
                          </p>
                        </div>
                      </div>
                      </div>


                      <div className="panel panel-default panel-help">
                        <a href="#t5" data-toggle="collapse" data-parent="#help-accordion-1" className="collapsed">
                          <div className="panel-heading">
                            <h2>
                      How can you guarantee the technology won’t allow a coin to be forked?
                            </h2>
                          </div>
                        </a>
                      <div id="t5" className="collapse">
                        <div className="panel-body">
                          <p>
                          The technology prevents any chance of a malicious fork, through the use of highly secure random number generation and the random selection of a leader node. Should the leader node be malicious, it only has 60 seconds before it’s status as the leader is removed.</p>

                        </div>
                      </div>
                      </div>



                      <div className="panel panel-default panel-help">
                        <a href="#t6" data-toggle="collapse" data-parent="#help-accordion-1" className="collapsed">
                          <div className="panel-heading">
                            <h2>
                      Can the Bitcoin blockchain speed be increased to compete?
                            </h2>
                          </div>
                        </a>
                      <div id="t6" className="collapse">
                        <div className="panel-body">
                      <p>
                        The Bitcoin networks speed can be increased and there are solutions out there contributing to significantly faster transaction speeds, but this is still combined with expensive and insecure PoW algorithms and inherent scalability issues due to the size of the chain.
                          </p>
                        </div>
                      </div>
                      </div>









                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="faqtab2" role="tabpanel" aria-labelledby="v-pills-tab2-tab">
                  <div className="panel-group" id="help-accordion-2">
                    <h3 className="display-4 pb-4 border-bottom">
                      Technology FAQ
                    </h3>
                    <div className="panel panel-default panel-help">
                      <a href="#c1" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          How long has the technology been in development?
                          </h2>
                        </div>
                      </a>
                      <div id="c1" className="collapse">
                        <div className="panel-body">
                          <p>
                          The technology has been a lifelong crusade of Richard Dennis, Mr Dennis began work on this back in 2014 when he was also working as a lecturer of cryptography begining in 2016 Mr Dennis committed to this effort full time engaged other developers and cryptographers, secured financing and built what is now known as the temporal blockchain.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default panel-help">
                      <a href="#c2" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          Is Quantum security really needed in cryptocurrencies? Aren’t most security breaches down to human error?

                          </h2>
                        </div>
                      </a>
                      <div id="c2" className="collapse">
                        <div className="panel-body">
                          <p>
                          We believe quantum security is critical for cryptocurrencies for the reasons outlined in the whitepaper.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default panel-help">
                      <a href="#c3" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          Doesn’t integrating into existing payment infrastructures go against the philosophy of decentralisation?

                          </h2>
                        </div>
                      </a>
                      <div id="c3" className="collapse">
                        <div className="panel-body">
                          <p>
                          temtum works under decentralization. Using existing payment infrastructure doesn’t compromise temtum but gives it a platform for greater adoption of the cryptocurrency and payment coin in daily life.


                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default panel-help">
                      <a href="#c4" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          How does Temporal compare to the likes of Hashgraph and Directed Acyclic Graph technology?

                          </h2>
                        </div>
                      </a>
                      <div id="c4" className="collapse">
                        <div className="panel-body">
                          <p>
                          Temporal compares extremely favourable against hashgraph and other similar type of blockchain networks for many reasons including security and scalability amoung others. Please see the technology section on the white paper.


                          </p>
                        </div>
                      </div>
                    </div>



                    <div className="panel panel-default panel-help">
                      <a href="#c5" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          The quantum source random number generation uses 3rd party technology, does this risk the network being offline at any point?

                          </h2>
                        </div>
                      </a>
                      <div id="c5" className="collapse">
                        <div className="panel-body">
                          <p>
                          Generally this is a pertinent point and one we recognized needed to be addressed. We have addressed this though the use of other randomness beacons and are developing our own in house solution.

                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default panel-help">
                      <a href="#c6" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>

                          Can the transaction speeds be verified on mainnet?
                          </h2>
                        </div>
                      </a>
                      <div id="c6" className="collapse">
                        <div className="panel-body">
                          <p>

                          Yes - as illustrated on the live demonstration provided on the website.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default panel-help">
                      <a href="#c7" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          What is the NIST beacon?
                          </h2>
                        </div>
                      </a>
                      <div id="c7" className="collapse">
                        <div className="panel-body">
                          <p>
                          The NIST beacon is a US government backed random number generator, utilising sources of light to create genuinely unpredictable numbers.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default panel-help">
                      <a href="#c8" data-toggle="collapse" data-parent="#help-accordion-2" className="collapsed">
                        <div className="panel-heading">
                          <h2>
Why is the NIST beacon better than the alternatives?
                          </h2>
                        </div>
                      </a>
                      <div id="c8" className="collapse">
                        <div className="panel-body">
                          <p>
                          Alternative random number generators use software to create numbers, which leaves them exposed to predictability in that an algorithm is generating the value. temtum makes use of quantum sources of randomness from NIST in our timestamps and in the selection of leader nodes. This protects the temtum network from bad actors and double spend attacks.
                          </p>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>


                <div className="tab-pane fade" id="faqtab3" role="tabpanel" aria-labelledby="v-pills-tab3-tab">
                  <div className="panel-group" id="help-accordion-3">
                    <h3 className="display-4 pb-4 border-bottom">
                      Network FAQ
                    </h3>
                    <div className="panel panel-default panel-help">
                      <a href="#b1" data-toggle="collapse" data-parent="#help-accordion-3" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          Why would a node join the network without rewards?


                          </h2>
                        </div>
                      </a>
                      <div id="b1" className="collapse">
                        <div className="panel-body">
                          <p>
                          Nodes are known for joining networks without rewards for many reasons this would not be unprecedented.

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default panel-help">
                      <a href="#b2" data-toggle="collapse" data-parent="#help-accordion-3" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                                                    What’s the benefit of having low resource devices on the network?
                                                  </h2>
                        </div>
                      </a>
                      <div id="b2" className="collapse">
                        <div className="panel-body">

                          <p>
                          It increases the potential cost for an attacker to Sybil the network.


                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default panel-help">
                      <a href="#b3" data-toggle="collapse" data-parent="#help-accordion-3" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          Does the Performance Integrity Protocol make the addition of low resourced nodes pointless?


                          </h2>
                        </div>
                      </a>
                      <div id="b3" className="collapse">
                        <div className="panel-body">
                          <p>  No</p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default panel-help">
                      <a href="#b4" data-toggle="collapse" data-parent="#help-accordion-3" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          Is temtum a public or private network?



                          </h2>
                        </div>
                      </a>
                      <div id="b4" className="collapse">
                        <div className="panel-body">
                          <p>  temtum is currently a private network, but once the reputation system has finished development as outlined in the roadmap, the network will be made public.</p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default panel-help">
                      <a href="#b5" data-toggle="collapse" data-parent="#help-accordion-3" className="collapsed">
                        <div className="panel-heading">
                          <h2>

                          How does temtum ensure new nodes are not malicious?

                          </h2>
                        </div>
                      </a>
                      <div id="b5" className="collapse">
                        <div className="panel-body">
                          <p>
                          Our unique reputation system monitors all nodes for malicious behaviour before allowing them to participate on the network.</p>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default panel-help">
                      <a href="#b6" data-toggle="collapse" data-parent="#help-accordion-3" className="collapsed">
                        <div className="panel-heading">
                          <h2>

                        Can Dragon shut down the network?

                          </h2>
                        </div>
                      </a>
                      <div id="b6" className="collapse">
                        <div className="panel-body">
                          <p>
                          No, Dragon have simply provided the incredible technology of the Temporal Blockchain, the temtum Network is completely independent of Dragon and its team.</p>
                        </div>
                      </div>
                    </div>


                    <div className="panel panel-default panel-help">
                      <a href="#b7" data-toggle="collapse" data-parent="#help-accordion-3" className="collapsed">
                        <div className="panel-heading">
                          <h2>
                          As you increase the use cases, additionally from coins, will the speed of the blockchain slow?
                          </h2>
                        </div>
                      </a>
                      <div id="b7" className="collapse">
                        <div className="panel-body">
                          <p>
The temtum Network gets faster and more secure the bigger it gets. The Network will scale with adoption, meaning temtum only improves as new integrations are launched.
                          </p>
                        </div>
                      </div>
                    </div>
















                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</Layout> )
export default Faq
